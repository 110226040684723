import React from "react"
import { Link } from "gatsby"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Company from "../components/Company"
// import CompanyGallery from "../components/companyGallery"
import CompanyGallery from '../components/CompanyGallery';

const GajurmukhiPage = () => (
  <Layout showOnlyHome={true}>
    <SEO title="Gajurmukhi Herbal" />
    <Company
      title="Gajurmukhi Herbal Pvt. Ltd."
      website="https://gajurmukhiherbal.com"
      phone="+977-012323434"
      email="info@gajurmukhiherbal.com"
    >
      <div className="images">
        <Zoom>
          <img src="/company/gajurmukhi1.jpg" />
        </Zoom>
      </div>
      <div className="content">
        <h5>Company Detail</h5>
        <p>
          100% Organic Herbal Essential oils Manufacturer, Processer, Farming &
          plantation and Export, Import.{" "}
        </p>
        <h5>Objective</h5>
        <p>
          00% Organic Herbal Essential oils and plant extracts Manufacturer,
          Processer, Farming & plantation and Export.
        </p>
        <address>
          OCR Registration No.: 34924/061/062
          <br />
          PAN/VAT No.: 301931191
          <br />
          Year of Establishment: 2005
          <br />
          Corporate Address: Kakadvitta ward no. - 10, Mechinagar Municipality,
          <br />
          Jhapa, Nepal.
          <br />
        </address>
        <h5> Key Persons: Mr. Basanta Rokka Chhetri </h5>
        <ul>
          <li>(Chairman) Mr. Bharat Bdr Rokka Chhetri (Director)</li>
          <li>Mr. Ishowar Bdr Rokka Chhetri (Director)</li>
          <li>Mr. Mukesh Basnet (Director)</li>
          <li>Mr. Deepak Rokka (Director)</li>
          <li>Mr. Bharat Kumar Basnet (Director)</li>
          <li>Mr. Kumar Rokka Chhetri (Director)</li>
          <li>Mr. Kumar Rokka Chhetri (Director)</li>
        </ul>
      </div>
      <div className="map">
        <iframe
          id="map-iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3566.2965406597896!2d88.13290621541759!3d26.6389862832463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e5b2fc3f1d06ad%3A0x59d3214c6dcd3582!2sGajurmukhi%20Herbal%20Organic%20Farming!5e0!3m2!1sen!2snp!4v1585976703754!5m2!1sen!2snp"
          frameborder="0"
          allowfullscreen=""
        ></iframe>
      </div>
      <div className="company-gallery">
        <CompanyGallery name="gajurmukhi" />
      </div>
    </Company>
  </Layout>
)

export default GajurmukhiPage
