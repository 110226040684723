import React from "react"

const data = {
  aachyut: [
    { type: "file", name: "BSBZ6464.JPG" },
    { type: "file", name: "CULW2104.JPG" },
    { type: "file", name: "Chamomile .JPG" },
    { type: "file", name: "DRSU0100.JPG" },
    { type: "file", name: "Distillation Vessel.mp4" },
    { type: "file", name: "IMG_0573.PNG" },
    { type: "file", name: "IMG_0574.PNG" },
    { type: "file", name: "IMG_0575.PNG" },
    { type: "file", name: "IMG_4201.JPG" },
    { type: "file", name: "IMG_5831.JPG" },
    { type: "file", name: "IMG_7052.JPG" },
    { type: "file", name: "IMG_7471.JPG" },
    { type: "file", name: "IMG_7579.JPG" },
    { type: "file", name: "IMG_7778.JPG" },
    { type: "file", name: "IMG_7779.JPG" },
    { type: "file", name: "IMG_8798.JPG" },
    { type: "file", name: "IMG_8799.JPG" },
    { type: "file", name: "Initial Farming .JPG" },
    { type: "file", name: "Lab testing.MOV" },
    { type: "file", name: "MHEL0202.JPG" },
    { type: "file", name: "Plant Pic.JPG" },
    { type: "file", name: "Product Dteila.JPG" },
    { type: "file", name: "Product.PNG" },
    { type: "file", name: "Product0.PNG" },
  ],
  gajurmukhi: [
    { type: "file", name: "ACNG0536.JPG" },
    { type: "file", name: "AQFP6450.JPG" },
    { type: "file", name: "BIZI0161.JPG" },
    { type: "file", name: "DAIK0013.JPG" },
    { type: "file", name: "DTED3792.JPG" },
    { type: "file", name: "FKUK0257.JPG" },
    { type: "file", name: "HDSJ4436.JPG" },
    { type: "file", name: "HEXT9210.JPG" },
    { type: "file", name: "HPBP7351.JPG" },
    { type: "file", name: "IMG_0877.JPG" },
    { type: "file", name: "IMG_0879.JPG" },
    { type: "file", name: "IMG_1312.JPG" },
    { type: "file", name: "IMG_7199.JPG" },
    { type: "file", name: "IMG_7206.JPG" },
    { type: "file", name: "IMG_7274.MOV" },
    { type: "file", name: "IMG_9188.JPG" },
    { type: "file", name: "IMG_9198.JPG" },
    { type: "file", name: "JPDK1847.JPG" },
    { type: "file", name: "LLAO4187.JPG" },
    { type: "file", name: "MMXC7189.JPG" },
    { type: "file", name: "MTQJ0941.JPG" },
    { type: "file", name: "SBDK4103.JPG" },
    { type: "file", name: "XYKH4910.JPG" },
  ],
  kasthamandal: [
    { type: "file", name: "Compressive Test of  timber.MOV" },
    { type: "file", name: "IMG_2282.JPG" },
    { type: "file", name: "IMG_2322.JPG" },
    { type: "file", name: "IMG_2324.JPG" },
    { type: "file", name: "IMG_2362.JPG" },
    { type: "file", name: "IMG_2422.JPG" },
  ],
  kattha: [
    { type: "file", name: "AMZFE7561.JPG" },
    { type: "file", name: "AUWT5615.JPG" },
    { type: "file", name: "AXDM6491.JPG" },
    { type: "file", name: "AXDME6491.JPG" },
    { type: "file", name: "After Cristlizetion.mp4" },
    { type: "file", name: "After Extraction Dry Chips Storage.jpeg" },
    { type: "file", name: "Auto Clav.jpeg" },
    { type: "file", name: "Boiler Area.jpeg" },
    { type: "file", name: "Chipper and comveyer.jpeg" },
    { type: "file", name: "Chipper.jpeg" },
    { type: "file", name: "Conveyer.jpeg" },
    { type: "file", name: "Cristlazation pic.jpeg" },
    { type: "file", name: "Cristlazation..jpeg" },
    { type: "file", name: "Cristlazation.j.jpeg" },
    { type: "file", name: "Cristlazation.jpeg" },
    { type: "file", name: "Cutting Section.jpeg" },
    { type: "file", name: "DMCJ1520.JPG" },
    { type: "file", name: "DMCJE1520.JPG" },
    { type: "file", name: "DSWN2686.JPG" },
    { type: "file", name: "DSWNE2686.JPG" },
    { type: "file", name: "Deshi Biscuts.jpeg" },
    { type: "file", name: "Deshi Plating.JPG" },
    { type: "file", name: "Drying of Biscuts.jpeg" },
    { type: "file", name: "EPLX8714.JPG" },
    { type: "file", name: "Extraction Area _ Autoclav.jpeg" },
    { type: "file", name: "FNPJ2412.JPG" },
    { type: "file", name: "Factory Google Image.PNG" },
    { type: "file", name: "Filteration and cutting Area.jpeg" },
    { type: "file", name: "Filteration process by Hydrolic press.jpeg" },
    { type: "file", name: "Filteration process.jpeg" },
    { type: "file", name: "Hydrolic Press.jpeg" },
    { type: "file", name: "IMG_0916.JPG" },
    { type: "file", name: "IMG_0921.JPG" },
    { type: "file", name: "IMG_1082.JPG" },
    { type: "file", name: "IMG_1214.JPG" },
    { type: "file", name: "IMG_1218.JPG" },
    { type: "file", name: "IMG_1412.JPG" },
    { type: "file", name: "IMG_1592.JPG" },
    { type: "file", name: "IMG_2878.JPG" },
    { type: "file", name: "IMG_2909.JPG" },
    { type: "file", name: "IMG_2912.JPG" },
    { type: "file", name: "IMG_2925.JPG" },
    { type: "file", name: "IMG_2927.JPG" },
    { type: "file", name: "IMG_2940.JPG" },
    { type: "file", name: "IMG_2950.JPG" },
    { type: "file", name: "IMG_3010.JPG" },
    { type: "file", name: "IMG_3031.JPG" },
    { type: "file", name: "IMG_3070.JPG" },
    { type: "file", name: "IMG_3071.JPG" },
    { type: "file", name: "IMG_3097.JPG" },
    { type: "file", name: "IMG_3108.JPG" },
    { type: "file", name: "IMG_3121.JPG" },
    { type: "file", name: "IMG_3128.JPG" },
    { type: "file", name: "IMG_4028.JPG" },
    { type: "file", name: "IMG_4031.JPG" },
    { type: "file", name: "IMG_4180.JPG" },
    { type: "file", name: "IMG_4181.JPG" },
    { type: "file", name: "IMG_4412.JPG" },
    { type: "file", name: "IMG_4571.JPG" },
    { type: "file", name: "IMG_6620.JPG" },
    { type: "file", name: "IMG_6621.JPG" },
    { type: "file", name: "IMG_6622.JPG" },
    { type: "file", name: "IMG_6623.JPG" },
    { type: "file", name: "IMG_6624.JPG" },
    { type: "file", name: "IMG_6626.JPG" },
    { type: "file", name: "IMG_6715.MOV" },
    { type: "file", name: "IMG_6985.JPG" },
    { type: "file", name: "IMG_6987.JPG" },
    { type: "file", name: "IMG_7014.MOV" },
    { type: "file", name: "IMG_7015.JPG" },
    { type: "file", name: "IMG_7285.JPG" },
    { type: "file", name: "IMG_7329.JPG" },
    { type: "file", name: "IMG_7643.JPG" },
    { type: "file", name: "IMG_7758.JPG" },
    { type: "file", name: "IMG_7759.JPG" },
    { type: "file", name: "IMG_7760.JPG" },
    { type: "file", name: "IMG_7762.JPG" },
    { type: "file", name: "IMG_8184.JPG" },
    { type: "file", name: "IMG_8261.JPG" },
    { type: "file", name: "IMG_8404.PNG" },
    { type: "file", name: "IMG_8406.PNG" },
    { type: "file", name: "IMG_8407.PNG" },
    { type: "file", name: "IMG_8412.PNG" },
    { type: "file", name: "IMG_8415.PNG" },
    { type: "file", name: "IMG_8418.PNG" },
    { type: "file", name: "IMG_8461.PNG" },
    { type: "file", name: "IMG_E1291.JPG" },
    { type: "file", name: "IMG_E1398.MOV" },
    { type: "file", name: "IMG_E1682.JPG" },
    { type: "file", name: "IMG_E3108.JPG" },
    { type: "file", name: "IMG_E7234.JPG" },
    { type: "file", name: "IMG_E8633.JPG" },
    { type: "file", name: "IMG_E8634.JPG" },
    { type: "file", name: "IMG_E8638.JPG" },
    { type: "file", name: "IMG_E8655.JPG" },
    { type: "file", name: "IMG_E8656.JPG" },
    { type: "file", name: "IMG_E8661.JPG" },
    { type: "file", name: "IMG_E8662.JPG" },
    { type: "file", name: "IMG_E9043.JPG" },
    { type: "file", name: "IMG_E9149.JPG" },
    { type: "file", name: "JGBLE3636.JPG" },
    { type: "file", name: "JRVX3537.JPG" },
    { type: "file", name: "JZAA2324.JPG" },
    { type: "file", name: "KSOO8567.JPG" },
    { type: "file", name: "Kattha Factory Pic (1).JPG" },
    { type: "file", name: "Kattha Factory Pic (1).MOV" },
    { type: "file", name: "Kattha Factory Pic (1).PNG" },
    { type: "file", name: "Kattha Factory Pic (10).JPG" },
    { type: "file", name: "Kattha Factory Pic (10).PNG" },
    { type: "file", name: "Kattha Factory Pic (11).JPG" },
    { type: "file", name: "Kattha Factory Pic (11).PNG" },
    { type: "file", name: "Kattha Factory Pic (12).JPG" },
    { type: "file", name: "Kattha Factory Pic (12).PNG" },
    { type: "file", name: "Kattha Factory Pic (13).JPG" },
    { type: "file", name: "Kattha Factory Pic (13).PNG" },
    { type: "file", name: "Kattha Factory Pic (2).JPG" },
    { type: "file", name: "Kattha Factory Pic (2).PNG" },
    { type: "file", name: "Kattha Factory Pic (3).JPG" },
    { type: "file", name: "Kattha Factory Pic (3).PNG" },
    { type: "file", name: "Kattha Factory Pic (4).JPG" },
    { type: "file", name: "Kattha Factory Pic (4).PNG" },
    { type: "file", name: "Kattha Factory Pic (5).JPG" },
    { type: "file", name: "Kattha Factory Pic (5).PNG" },
    { type: "file", name: "Kattha Factory Pic (6).JPG" },
    { type: "file", name: "Kattha Factory Pic (6).PNG" },
    { type: "file", name: "Kattha Factory Pic (7).JPG" },
    { type: "file", name: "Kattha Factory Pic (7).PNG" },
    { type: "file", name: "Kattha Factory Pic (8).JPG" },
    { type: "file", name: "Kattha Factory Pic (8).PNG" },
    { type: "file", name: "Kattha Factory Pic (9).JPG" },
    { type: "file", name: "Kattha Factory Pic (9).PNG" },
    { type: "file", name: "Kattha Loading.JPG" },
    { type: "file", name: "Kattha Packaging (1).JPG" },
    { type: "file", name: "Kattha Packaging (2).JPG" },
    { type: "file", name: "Khair Raw (1).JPG" },
    { type: "file", name: "Khair Raw (1).MOV" },
    { type: "file", name: "Khair Raw (2).JPG" },
    { type: "file", name: "Khair Raw (3).JPG" },
    { type: "file", name: "Khair Raw (4).JPG" },
    { type: "file", name: "LEOA6052.JPG" },
    { type: "file", name: "Machini Biscuts.jpeg" },
    { type: "file", name: "NNZM3951.JPG" },
    { type: "file", name: "NVOW7332.JPG" },
    { type: "file", name: "Outlet of Chips After extraction.jpeg" },
    { type: "file", name: "Packing (2).jpeg" },
    { type: "file", name: "Packing Area.jpeg" },
    { type: "file", name: "Packing.jpeg" },
    { type: "file", name: "Processing Pic (13).jpeg" },
    { type: "file", name: "Processing Pic (16).jpeg" },
    { type: "file", name: "Raw Khair (1).jpeg" },
    { type: "file", name: "Raw Khair (2).jpeg" },
    { type: "file", name: "Sacks Feeling By Conveyer.jpeg" },
    { type: "file", name: "Sawing Section.jpeg" },
    { type: "file", name: "Sawing and crushing section.jpeg" },
    { type: "file", name: "UMUJE7507.JPG" },
    { type: "file", name: "UPNG2254.JPG" },
    { type: "file", name: "UTDQ2035.JPG" },
    { type: "file", name: "VBEZE2620.JPG" },
    { type: "file", name: "Vaccum Pan.jpeg" },
    { type: "file", name: "WFQQE2575.JPG" },
    { type: "file", name: "WHEQ0725.JPG" },
    { type: "file", name: "XAUE9218.JPG" },
    { type: "file", name: "XAUEE9218.JPG" },
  ],
  rosin: [
    { type: "file", name: "Distillation Unit And Settiling Unit.jpeg" },
    { type: "file", name: "Distllation Plant.jpeg" },
    { type: "file", name: "Filtration.jpeg" },
    { type: "file", name: "GI Barrel 25 K.G Pack.jpeg" },
    { type: "file", name: "Heating and melting.jpeg" },
    { type: "file", name: "Processing Plant.jpeg" },
    { type: "file", name: "Raw Resin Melting Vat.jpeg" },
    { type: "file", name: "Resin Boiling Area.jpeg" },
    { type: "file", name: "Resin Extracting Fom Tina.jpeg" },
    { type: "file", name: "Resin Settling tank.jpeg" },
    { type: "file", name: "Resin Tina.jpeg" },
    { type: "file", name: "Rosin Higher Grade.j.jpeg" },
    { type: "file", name: "Rosin Higher Grade.jpeg" },
    { type: "file", name: "Rosin Processing Pic (1).jpeg" },
    { type: "file", name: "Rosin Processing Pic (10).jpeg" },
    { type: "file", name: "Rosin Processing Pic (11).jpeg" },
    { type: "file", name: "Rosin Processing Pic (12).jpeg" },
    { type: "file", name: "Rosin Processing Pic (13).jpeg" },
    { type: "file", name: "Rosin Processing Pic (2).jpeg" },
    { type: "file", name: "Rosin Processing Pic (3).jpeg" },
    { type: "file", name: "Rosin Processing Pic (4).jpeg" },
    { type: "file", name: "Rosin Processing Pic (5).jpeg" },
    { type: "file", name: "Rosin Processing Pic (6).jpeg" },
    { type: "file", name: "Rosin Processing Pic (7).jpeg" },
    { type: "file", name: "Rosin Processing Pic (8).jpeg" },
    { type: "file", name: "Rosin Processing Pic (9).jpeg" },
    { type: "file", name: "Settling Tank.jpeg" },
    { type: "file", name: "Settling Tanks.jpeg" },
    { type: "file", name: "Settling Vessel.jpeg" },
    { type: "file", name: "Settling and Washing Vat.jpeg" },
    { type: "file", name: "Settling unit.jpeg" },
    { type: "file", name: "Settling vat.jpeg" },
    { type: "file", name: "Steem Distllation Plant For Rosin.jpeg" },
    { type: "file", name: "Stock of Raw Resin.jpeg" },
    { type: "file", name: "Storage Tank Of Turpentine.jpeg" },
    { type: "file", name: "Tina 15 K.G pack.jpeg" },
    { type: "file", name: "WhatsApp Image 2019-08-29 at 10.42.29 PM.jpeg" },
    { type: "file", name: "WhatsApp Image 2019-08-29 at 10.49.45 PM.jpeg" },
    { type: "file", name: "rosin pics.jpeg" },
    { type: "file", name: "washing.jpeg" },
  ],
}

const CompanyGallery = props => {
  const { name } = props
  return null
  if (Object.keys(data).indexOf(name) <= -1) {
    return null
  }
  return data[name].map((item, i) => {
    return (
      <img
        src={`/gallery/companies/${name}/${item.name}`}
        alt={item.name}
      />
    )
  })
}

export default CompanyGallery
